export interface LinkViewModel {
  name: string;
  target: string;
  url: string;
}

export function defaultLink(): LinkViewModel {
  return {
    name: "",
    target: "_self",
    url: "#",
  };
}
