import { useEffect, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { FactViewModel } from "../view-models/fact-view-model";
import { FeatureBlockViewModel } from "../view-models/feature-block-view-model";
import { ImageViewModel } from "../view-models/image-view-model";
import "./Location.scss";
import Picture from "./Picture";
import PiedmontDescription from "./PiedmontDescription";
import PiedmontFacts from "./PiedmontFacts";
import PiedmontHero from "./PiedmontHero";
import PiedmontSeparator from "./PiedmontSeparator";
import PiedmontSpacer from "./PiedmontSpacer";
import RawHtml from "./RawHtml";

export interface LocationProps {
  className?: string;
}
const Location = (props: LocationProps) => {
  const [pageTitle, setPageTitle] = useState("");
  const [heroImage, setHeroImage] = useState<ImageViewModel>({});
  const [description, setDescription] = useState("");
  const [blocks, setBlocks] = useState<FeatureBlockViewModel[]>([]);
  const [facts, setFacts] = useState<FactViewModel[]>([]);

  useEffect(() => {
    UmbracoApi.getLocationContent().then((location) => {
      setPageTitle(location.pageTitle);
      setHeroImage(location.heroImage);
      setDescription(location.description);
      setBlocks(location.blocks);
      setFacts(location.facts);

      console.log(location.facts);
    });
  }, []);

  return (
    <div className={`location ${props.className}`}>
      <div className="ui container">
        <PiedmontHero title={pageTitle} image={heroImage} />

        <PiedmontSeparator thick={true} />
        <PiedmontDescription html={description} />

        <PiedmontSeparator />
        <div className="blocks">
          {blocks.map((block) => {
            return (
              <div className="block">
                <Picture image={block.image} />
                <div className="title">{block.title}</div>
                <RawHtml html={block.description} />
              </div>
            );
          })}
        </div>

        <div className="desktop">
          <PiedmontSeparator />
          <PiedmontFacts
            embiggeningStyle="even"
            smallerText={true}
            evenItems={true}
            facts={facts}
            colored={false}
          />
        </div>

        <PiedmontSeparator thick={true} />
      </div>

      <PiedmontSpacer />
    </div>
  );
};

export default Location;
