import { CommonProps } from "../util/CommonProps";
import { ImageViewModel } from "../view-models/image-view-model";
import "./Picture.scss";

interface PictureProps extends CommonProps {
  image?: ImageViewModel;
  width?: number;
  height?: number;
}

const Picture = (props: PictureProps) => (
  <picture className={props.className}>
    <source srcSet={props.image?.desktopUrl} media="(min-width: 1024px)" />
    <source srcSet={props.image?.tabletUrl} media="(min-width: 768px)" />

    {/* eslint-disable jsx-a11y/img-redundant-alt */}
    <img
      src={props.image?.mobileUrl}
      alt={props.image?.alt}
      width={props.width}
      height={props.height}
    />
  </picture>
);

export default Picture;
