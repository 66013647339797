import { useEffect, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { ImageViewModel } from "../view-models/image-view-model";
import PiedmontSeparator from "./PiedmontSeparator";
import PressRelease from "./PressRelease";
import "./PressReleasePage.scss";

const PressReleasePage = () => {
  const [pressRelease, setPressRelease] = useState("");
  const [pressReleaseImage, setPressReleaseImage] = useState<ImageViewModel>(
    {}
  );
  const params = new URLSearchParams(window.location.search);
  const releaseIndex = params.get("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const releaseIndex = params.get("releaseIndex") ?? "";

    UmbracoApi.getPressRelease(releaseIndex).then((news) => {
      setPressReleaseImage(news.pressReleaseImage);
      setPressRelease(news.pressRelease);
    });
  }, []);

  return (
    <div className="press-release-page">
      <div className="ui container">
        <h2 className="header">Press Release</h2>
        <PiedmontSeparator thick />

        <PressRelease
          releaseId={0}
          image={pressReleaseImage}
          fullRelease={true}
          color="darkblue"
          content={pressRelease}
        />
      </div>
    </div>
  );
};

export default PressReleasePage;
