import { PagePath } from "./pagePath";

export enum LinkColor {
  White = "white",
  Red = "red",
  Blue = "blue",
  Green = "green",
  DarkBlue = "darkblue",
}

export function getLinkColor(link: PagePath): LinkColor {
  switch (link) {
    case PagePath.Home:
      return LinkColor.White;

    case PagePath.About:
      return LinkColor.Red;

    case PagePath.ThingsToDo:
      return LinkColor.Green;

    case PagePath.Location:
      return LinkColor.Blue;

    case PagePath.News:
      return LinkColor.White;

    case PagePath.Leasing:
      return LinkColor.White;

    case PagePath.PressRelease:
      return LinkColor.White;

    default:
      return LinkColor.White;
  }
}
