import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Picture from "./Picture";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { defaultLink, LinkViewModel } from "../view-models/link-view-model";
import { ImageViewModel } from "../view-models/image-view-model";
import RawHtml from "./RawHtml";
import { CommonProps } from "../util/CommonProps";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface FooterProps extends CommonProps {}

const Footer = (props: FooterProps) => {
  const [address, setAddress] = useState("");
  const [links, setLinks] = useState<LinkViewModel[]>([]);
  const [facebook, setFacebook] = useState<LinkViewModel>(defaultLink());
  const [instagram, setInstagram] = useState<LinkViewModel>(defaultLink());
  const [mapImage, setMapImage] = useState<ImageViewModel>();
  const [mapLink, setMapLink] = useState<LinkViewModel>(defaultLink());
  const [logo, setLogo] = useState<ImageViewModel>();
  const [logoLink, setLogoLink] = useState<LinkViewModel>(defaultLink());

  useEffect(() => {
    UmbracoApi.getFooterContent()
      .then((footer) => {
        setAddress(footer.address);
        if (footer.facebookLink) setFacebook(footer.facebookLink);
        if (footer.instagramLink) setInstagram(footer.instagramLink);
        setMapImage(footer.mapImage);
        setMapLink(footer.mapLink);
        setLogo(footer.logo);
        setLogoLink(footer.logoLink);
      })
      .catch((_) => {
        console.error("Couldn't fetch footer content");
      });

    UmbracoApi.getNavContent()
      .then((nav) => {
        if (nav.links) setLinks(nav.links);
      })
      .catch((_) => {
        console.error("Couldn't fetch nav content in footer");
      });
  }, []);

  return (
    <div className={`${props.className} footer`}>
      <div className="ui container">
        <div className="grid">
          <div className="icons">
            <a className="icon" href={facebook.url} target={facebook.target}>
              <FontAwesomeIcon icon={faFacebookSquare as IconProp} />
            </a>
            <a href={instagram.url} target={instagram.target} className="icon">
              <FontAwesomeIcon icon={faInstagram as IconProp} />
            </a>
          </div>

          <RawHtml html={address} className="address-section" />

          <div className="links-section">
            {links.map((l) => (
              <div key={`${l.name}${l.target}${l.url}`}>
                <Link to={l.url} target={l.target}>
                  {l.name}
                </Link>
                <br />
              </div>
            ))}
          </div>

          <div className="button-container">
            <a href={mapLink.url} target={mapLink.target}>
              <span className="maps-button">{mapLink.name}</span>
            </a>
          </div>

          <div className="map-section">
            <a href={mapLink.url} target={mapLink.target}>
              <Picture image={mapImage} />
            </a>
          </div>

          <div className="logo-section">
            <a href={logoLink.url} target={logoLink.target}>
              <Picture image={logo} />
            </a>
          </div>

          <div className="copyright-section">
            <span className="text">© 2021 Ardent Companies</span>
          </div>

          <div className="wilbert-design">
            <span className="text">Site Designed by The Wilbert Group</span>
          </div>
        </div>
      </div>

      <div className="spacer"></div>
    </div>
  );
};

export default Footer;
