import "./semantic/dist/semantic.min.css";
import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Homepage from "./components/Homepage";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import News from "./components/News";
import About from "./components/About";
import ThingsToDo from "./components/ThingsToDo";
import Location from "./components/Location";
import { PagePath } from "./util/pagePath";
import Leasing from "./components/Leasing";
import { ParallaxProvider } from "react-scroll-parallax";
import PressReleasePage from "./components/PressReleasePage";

const App = (props: any) => {
  return (
    <BrowserRouter>
      <ParallaxProvider>
        <Navbar />
        <main>
          <Switch>
            <Route path={PagePath.Home} exact component={Homepage} />
            <Route path={PagePath.About} exact component={About} />
            <Route path={PagePath.ThingsToDo} exact component={ThingsToDo} />
            <Route path={PagePath.Location} exact component={Location} />
            <Route path={PagePath.News} exact component={News} />
            <Route path={PagePath.Leasing} exact component={Leasing} />
            <Route
              path={PagePath.PressRelease}
              exact
              component={PressReleasePage}
            />
          </Switch>
        </main>
        <Footer />
      </ParallaxProvider>
    </BrowserRouter>
  );
};

export default App;
