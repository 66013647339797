import "./PiedmontDescription.scss";
import RawHtml from "./RawHtml";

type PiedmontDescriptionColor = "dark-blue" | "white";

export interface PiedmontDescriptionProps {
  className?: string;
  html: string;
  color?: PiedmontDescriptionColor;
}

const PiedmontDescription = (props: PiedmontDescriptionProps) => {
  const colorStyle: PiedmontDescriptionColor = props.color ?? "white";

  return (
    <div className={`piedmont-description ${props.className} ${colorStyle}`}>
      <RawHtml html={props.html} />
    </div>
  );
};

export default PiedmontDescription;
