import { useEffect, useMemo, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { NewsItemViewModel } from "../view-models/news-item-view-model";
import Picture from "./Picture";
import PiedmontSeparator from "./PiedmontSeparator";
import RawHtml from "./RawHtml";
import "./NewsList.scss";
import PressRelease from "./PressRelease";

export interface NewsListProps {
  className?: string;
}
const NewsList = (props: NewsListProps) => {
  const [newsItems, setNewsItems] = useState<NewsItemViewModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    console.log("newsItems", newsItems);
  }, [newsItems]);

  const loadMoreSection = useMemo(() => {
    return LoadMore(hasMore);
  }, [hasMore]);

  useEffect(() => {
    console.log(`getting new page ${page}`);
    UmbracoApi.getNewsPage(page).then((news) => {
      setNewsItems((existing) => existing.concat(news.newsItems));
      setHasMore(news.hasMore);
    });
  }, [page]);

  useEffect(() => {
    console.log("setting initial page");
    setPage(0);
  }, []);

  function LoadMore(hasMore: boolean) {
    if (hasMore) {
      return (
        <>
          <PiedmontSeparator />
          <div className="centered">
            <button
              className="button"
              onClick={() => setPage((page) => page + 1)}
            >
              Load More
            </button>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <div className="news-list">
        {newsItems
          .filter((item) => item !== undefined)
          .map(
            (
              {
                type,
                blurb,
                index,
                attribution = "",
                link = { name: "Default", target: "_blank", url: "#" },
                image = {
                  alt: "default",
                  desktopUrl: "#",
                  mobileUrl: "#",
                  tabletUrl: "#",
                },
              },
              i
            ) => {
              const alernatingClassName = (i + 1) % 2 === 0 ? "inverted" : "";
              {
                console.log("type", type);
              }
              console.log("blurb", blurb);

              return (
                <div className="item" key={blurb}>
                  {type === "PressRelease" ? (
                    <PressRelease
                      color="blue"
                      content={blurb}
                      fullRelease={false}
                      releaseId={index}
                    />
                  ) : (
                    <div
                      className={`news-entry ${alernatingClassName}`}
                      key={link?.name}
                    >
                      <div className="image" key={image?.desktopUrl}>
                        <a
                          href={link?.url}
                          target={link?.target}
                          key={link?.url}
                        >
                          <Picture image={image} key={image?.desktopUrl} />
                        </a>
                      </div>

                      <div
                        className={`text ${image == null ? "fullwidth" : ""}`}
                        key={link?.name}
                      >
                        <a href={link?.url} target={link?.target}>
                          <span className="news-header">{link?.name}</span>
                        </a>
                        <br />
                        {attribution} <br />
                        {/* {prettyDate(item.date)} <br /> */}
                        <div className="blurb" key={blurb}>
                          <RawHtml html={blurb} />
                        </div>
                      </div>
                    </div>
                  )}

                  {i !== newsItems.length - 1 && <PiedmontSeparator />}
                </div>
              );
            }
          )}
      </div>

      <div>{loadMoreSection}</div>
    </>
  );
};

export default NewsList;
