import { FactViewModel } from "../view-models/fact-view-model";
import RawHtml from "./RawHtml";
import "./PiedmontFacts.scss";
import { useEffect, useRef, useState } from "react";

export type PiemdontFactsEmbiggeningStyle = "even" | "odd";

export interface PiedmontFactsProps {
  className?: string;
  colored: boolean;
  facts: FactViewModel[];
  embiggeningStyle: PiemdontFactsEmbiggeningStyle;
  smallerText?: boolean;
  evenItems?: boolean;
}

const getScrollMaxY = () => {
  return document.body.scrollHeight;
};

const PiedmontFacts = (props: PiedmontFactsProps) => {
  const [maxY, setMaxY] = useState(getScrollMaxY());
  const [activeInterval, setActiveInterval] = useState(
    setInterval(() => {}, 300)
  );
  const [scrolling, setScrolling] = useState(false);
  const factContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      window.addEventListener("scroll", () => {
        setScrolling(true);
      });
    }

    return () => {
      clearInterval(activeInterval);
    };
  }, []);

  useEffect(() => {
    clearInterval(activeInterval);

    setActiveInterval(
      setInterval(() => {
        if (scrolling && factContainerRef.current) {
          setScrolling(false);

          const y = window.scrollY;
          factContainerRef.current.style.transform = `translateX(-${
            (y / maxY) * 50
          }%)`;
        }
      }, 100)
    );
  }, [scrolling, maxY]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMaxY(getScrollMaxY());
  }, []);

  return (
    <div className="piedmont-facts">
      <div className="fact-container" ref={factContainerRef}>
        {props.facts
          .concat(props.facts)
          .concat(props.facts)
          .map((fact, i) => {
            let evenCondition = props.evenItems
              ? i % 2 === 0
              : i % 5 === 1 || i % 5 === 3;
            let oddCondition = props.evenItems
              ? (i + 1) % 2 === 0
              : i % 5 === 0 || i % 5 === 2 || i % 5 === 4;

            let style;
            if (props.embiggeningStyle === "even") {
              style = evenCondition ? "big" : "regular";
            } else {
              style = oddCondition ? "big" : "regular";
            }

            return {
              fact: fact,
              style: style,
            };
          })
          .map(({ style, ...rest }) => {
            return {
              style: `${style} ${props.smallerText ? "smaller" : ""}`,
              ...rest,
            };
          })
          .map((fact, i) => {
            const colors = ["blue", "green", "red", "macaroni", "green"];
            const selectedColor = props.colored
              ? colors[i % colors.length]
              : "white";
            return {
              color: selectedColor,
              ...fact,
            };
          })
          .map(({ fact, style, color }) => {
            return (
              <RawHtml
                className={`fact ${style} ${color}`}
                html={fact.description}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PiedmontFacts;
