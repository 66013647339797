import "./PiedmontSpacer.scss";

export interface PiedmontSpacerProps {
  className?: string;
}

const PiedmontSpacer = (props: PiedmontSpacerProps) => {
  return <div className={`piedmont-spacer ${props.className}`}></div>;
};

export default PiedmontSpacer;
