import { RefObject, useEffect, useMemo, useRef } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as LeasingMapSvg } from "../svg/leasing-map.svg";
import { BuildingInfoViewModel } from "../view-models/building-info-view-model";
import "./LeasingMap.scss";
import Picture from "./Picture";
import RawHtml from "./RawHtml";
import { ImageViewModel } from "../view-models/image-view-model";
import SwiperJS, { Autoplay, Pagination } from "swiper";
// import "swiper/swiper-bundle.min.css";

SwiperJS.use([Autoplay, Pagination]);

enum BuildingName {
  Building14 = "Building 14",
  Building15 = "Building 15",
  Commons = "Commons",
  Fountain = "Fountain",
  Buildings5_8 = "Buildings 5-8",
}

export interface LeasingMapProps {
  className?: string;
  buildings: BuildingInfoViewModel[];
}

const LeasingMap = (props: LeasingMapProps) => {
  const bldgs = props.buildings;

  const svgRef = useRef<SVGSVGElement>(null);
  const building14Ref = useRef<HTMLDivElement>(null);
  const building15Ref = useRef<HTMLDivElement>(null);
  const fountainsRef = useRef<HTMLDivElement>(null);
  const commonsRef = useRef<HTMLDivElement>(null);
  const buildings5_8Ref = useRef<HTMLDivElement>(null);

  const findFirstBuilding = (
    bldgName: string,
    bldgs: BuildingInfoViewModel[]
  ) => {
    return (): BuildingInfoViewModel => {
      const filtered = bldgs.filter((b) => b.buildingName.includes(bldgName));
      if (filtered.length > 0) return filtered[0];

      return {
        buildingName: bldgName,
        images: [],
        title: "Unknown Building",
        description: "",
      };
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const building14info = useMemo(
    findFirstBuilding(BuildingName.Building14, bldgs),
    [bldgs]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const building15info = useMemo(
    findFirstBuilding(BuildingName.Building15, bldgs),
    [bldgs]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fountainsInfo = useMemo(
    findFirstBuilding(BuildingName.Fountain, bldgs),
    [bldgs]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const commonsInfo = useMemo(findFirstBuilding(BuildingName.Commons, bldgs), [
    bldgs,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const buildings5_8Info = useMemo(
    findFirstBuilding(BuildingName.Buildings5_8, bldgs),
    [bldgs]
  );

  useEffect(() => {
    const building14 = svgRef.current?.querySelector("#Building_x5F_14");
    const building15 = svgRef.current?.querySelector("#Building_x5F_15");
    const fountains = svgRef.current?.querySelector("#Buildings_x5F_Blue");
    const commons = svgRef.current?.querySelector("#Building_x5F_Red");
    const green = svgRef.current?.querySelector("#Building_x5F_Green");

    const showBuilding = (ref: RefObject<HTMLDivElement>) => {
      ref.current?.classList.add("active");
    };

    const hideBuilding = (ref: RefObject<HTMLDivElement>) => {
      ref.current?.classList.remove("active");
    };

    building14?.addEventListener("mouseenter", () => {
      showBuilding(building14Ref);
    });
    building14?.addEventListener("mouseleave", () => {
      hideBuilding(building14Ref);
    });

    building15?.addEventListener("mouseenter", () => {
      showBuilding(building15Ref);
    });
    building15?.addEventListener("mouseleave", () => {
      hideBuilding(building15Ref);
    });

    fountains?.addEventListener("mouseenter", () => {
      showBuilding(fountainsRef);
    });
    fountains?.addEventListener("mouseleave", () => {
      hideBuilding(fountainsRef);
    });

    commons?.addEventListener("mouseenter", () => {
      showBuilding(commonsRef);
    });
    commons?.addEventListener("mouseleave", () => {
      hideBuilding(commonsRef);
    });

    green?.addEventListener("mouseenter", () => {
      showBuilding(buildings5_8Ref);
    });
    green?.addEventListener("mouseleave", () => {
      hideBuilding(buildings5_8Ref);
    });
  }, []);

  const BuildingSwiper = (props: { images: ImageViewModel[] }) => {
    return (
      // <Swiper
      //   className="building-swiper"
      //   pagination={true}
      //   autoplay={{ delay: 1000, disableOnInteraction: false }}
      //   loop={true}
      //   speed={1500}
      //   simulateTouch={false}
      // >
      //   {props.images.map((image) => (
      //     <SwiperSlide key={image.desktopUrl}>
      //       <Picture
      //         className="building-image"
      //         image={image}
      //         key={image.desktopUrl}
      //       />
      //     </SwiperSlide>
      //   ))}
      // </Swiper>
      <div className="building-swiper">
        <Picture className="building-image" image={props.images[0]} />
      </div>
    );
  };

  return (
    <div className={`leasing-map ${props.className}`}>
      <LeasingMapSvg ref={svgRef} />

      <div
        className="building-description background-red middle-right"
        ref={commonsRef}
      >
        <div className="title">{commonsInfo.title}</div>
        <BuildingSwiper images={commonsInfo.images} />
        <RawHtml className="description" html={commonsInfo.description} />
      </div>

      <div
        className="building-description background-green upper-right"
        ref={buildings5_8Ref}
      >
        <div className="title">{buildings5_8Info.title}</div>
        <BuildingSwiper images={buildings5_8Info.images} />
        <RawHtml className="description" html={buildings5_8Info.description} />
      </div>

      <div
        className="building-description background-blue middle-left"
        ref={fountainsRef}
      >
        <div className="title">{fountainsInfo.title}</div>
        <BuildingSwiper images={fountainsInfo.images} />
        <RawHtml className="description" html={fountainsInfo.description} />
      </div>

      <div
        className="building-description background-macaroni bottom-left"
        ref={building14Ref}
      >
        <div className="title">{building14info.title}</div>
        <BuildingSwiper images={building14info.images} />
        <RawHtml className="description" html={building14info.description} />
      </div>

      <div
        className="building-description background-macaroni upper-right"
        ref={building15Ref}
      >
        <div className="title">{building15info.title}</div>
        <BuildingSwiper images={building15info.images} />
        <RawHtml className="description" html={building15info.description} />
      </div>
    </div>
  );
};

export default LeasingMap;
