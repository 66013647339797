import { useEffect, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { FeatureBlockViewModel } from "../view-models/feature-block-view-model";
import { ImageViewModel } from "../view-models/image-view-model";
import "./About.scss";
import Picture from "./Picture";
import PiedmontDescription from "./PiedmontDescription";
import PiedmontHero from "./PiedmontHero";
import PiedmontSeparator from "./PiedmontSeparator";
import PiedmontSpacer from "./PiedmontSpacer";
import RawHtml from "./RawHtml";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export interface AboutProps {
  className: string;
}

const About = (props: AboutProps) => {
  const [pageTitle, setPageTitle] = useState("");
  const [heroImage, setHeroImage] = useState<ImageViewModel>({});
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [blocks, setBlocks] = useState<FeatureBlockViewModel[]>([]);

  useEffect(() => {
    UmbracoApi.getAboutPageContent().then((about) => {
      if (about?.pageTitle) setPageTitle(about.pageTitle);
      if (about?.heroImage) setHeroImage(about.heroImage);
      if (about?.subtitle) setSubtitle(about.subtitle);
      if (about?.description) setDescription(about.description);
      if (about?.blocks) setBlocks(about.blocks);
    });
  }, []);

  const addSlightAnimation = (el: HTMLDivElement) => {
    gsap.fromTo(
      el,
      {
        y: 200,
      },

      {
        y: 0,
        stagger: 0.2,
        scrollTrigger: {
          trigger: ".block-section",
          start: "top 70%",
          end: "top top",
          scrub: true,
        },
      }
    );
  };

  const addDrasticAnimation = (el: HTMLDivElement) => {
    gsap.fromTo(
      el,
      {
        y: 600,
      },

      {
        y: 0,
        stagger: 0.2,
        scrollTrigger: {
          trigger: ".block-section",
          start: "top 70%",
          end: "top top",
          scrub: true,
        },
      }
    );
  };

  return (
    <div className={`about ${props.className}`}>
      <div className="navbar-spacer"></div>

      <div className="ui container">
        <PiedmontHero title={pageTitle} image={heroImage} />

        <PiedmontSeparator thick={true} />
        <RawHtml className="subtitle" html={subtitle} />

        <PiedmontSeparator />
        <PiedmontDescription html={description} />

        <PiedmontSeparator />
        <div className="block-section">
          {blocks.map((block, i) => {
            const style = (i + 1) % 2 === 0 ? "staggered" : "regular";

            let animationFunction =
              (i + 1) % 2 === 0 ? addDrasticAnimation : addSlightAnimation;

            return (
              <div
                className={`item ${style}`}
                ref={animationFunction}
                key={block.title}
              >
                <Picture image={block.image} />
                <span className="header">{block.title}</span>
                <RawHtml className="description" html={block.description} />
              </div>
            );
          })}
        </div>

        <div className="separator-line"></div>

        <PiedmontSeparator thick={true} />
        <PiedmontSpacer />
      </div>
    </div>
  );
};

export default About;
