import { CommonProps } from "../util/CommonProps";

export interface RawHtmlProps extends CommonProps {
  className?: string;
  html: string;
}

const RawHtml = (props: RawHtmlProps) => {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.html }}
    ></div>
  );
};

export default RawHtml;
