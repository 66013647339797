import { useEffect, useState } from "react";
import { CommonProps } from "../util/CommonProps";
import UmbracoApi from "../util/umbracoApi";
import { ImageViewModel } from "../view-models/image-view-model";
import RawHtml from "./RawHtml";
import "./Homepage.scss";
import "swiper/swiper-bundle.min.css";
import PiedmontSwiper from "./PiedmontSwiper";
import PiedmontSeparator from "./PiedmontSeparator";
import PiedmontFacts from "./PiedmontFacts";
import { FactViewModel } from "../view-models/fact-view-model";

interface HomepageProps extends CommonProps {
  title: string;
}

const Homepage = (props: HomepageProps) => {
  const [videoLink, setVideoLink] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [images, setImages] = useState<ImageViewModel[]>([]);
  const [feedTitle, setFeedTitle] = useState("");
  const [curatorLoaded, setCuratorLoaded] = useState(false);
  const [facts, setFacts] = useState<FactViewModel[]>([]);

  useEffect(() => {
    UmbracoApi.getHomeContent().then((content) => {
      setVideoLink(content.videoLink);
      setSubtitle(content.subtitle);
      setImages(content.images);
      setFeedTitle(content.feedTitle);
      setFacts(content.facts);
    });
  }, []);

  useEffect(() => {
    if (!curatorLoaded) {
      let script = document.createElement("script");
      script.src =
        "https://cdn.curator.io/published/409d217b-33e0-4904-bdae-b6cc14ffd647.js";
      document.body.appendChild(script);
      setCuratorLoaded(true);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`${props.className} homepage`}>
      <div className="ui container">
        <div className="video-container">
          <RawHtml html={videoLink} />
        </div>

        <PiedmontSeparator />
        <RawHtml html={subtitle} className="subtitle" />

        <PiedmontSeparator />
        <div className="homepage-swiper-container">
          <PiedmontSwiper className="homepage-swiper" images={images} />
        </div>

        <div className="desktop">
          <PiedmontSeparator />
          <PiedmontFacts embiggeningStyle="odd" facts={facts} colored={true} />
        </div>

        <PiedmontSeparator />
        <div id="feed">
          <h2 className="title">{feedTitle}</h2>

          <div
            className="embed"
            data-crt-feed-id="409d217b-33e0-4904-bdae-b6cc14ffd647"
          ></div>
        </div>

        <PiedmontSeparator thick={true} />
      </div>
    </div>
  );
};

export default Homepage;
