import { AboutViewModel } from "../view-models/about-view-model";
import { FactListViewModel } from "../view-models/fact-list-view-model";
import { FooterViewModel } from "../view-models/footer-view-model";
import { HomeViewModel } from "../view-models/home-view-model";
import { LeasingViewModel } from "../view-models/leasing-view-model";
import { LocationViewModel } from "../view-models/location-view-model";
import { NavViewModel } from "../view-models/nav-view-model";
import { NewsItemViewModel } from "../view-models/news-item-view-model";
import { NewsPageResponseViewModel } from "../view-models/news-page-response-view-model";
import { NewsViewModel } from "../view-models/news-view-model";
import { ThingsToDoViewModel } from "../view-models/things-to-do-view-mode";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://piedmontapi.local/Umbraco/Api/Content"
    : "https://piedmont-api.azurewebsites.net/Umbraco/Api/Content";

export default class UmbracoApi {
  public static getHomeContent(): Promise<HomeViewModel> {
    return fetch(`${baseUrl}/GetHomeContent`)
      .then((res) => res.json())
      .then((res: HomeViewModel) => res);
  }

  public static getFooterContent(): Promise<FooterViewModel> {
    return fetch(`${baseUrl}/GetFooterContent`)
      .then((res) => res.json())
      .then((res: FooterViewModel) => res);
  }

  public static getNavContent(): Promise<NavViewModel> {
    return fetch(`${baseUrl}/GetNavContent`)
      .then((res) => res.json())
      .then((res: NavViewModel) => res);
  }

  public static getAboutPageContent(): Promise<AboutViewModel> {
    return fetch(`${baseUrl}/GetAboutContent`)
      .then((res) => res.json())
      .then((res: AboutViewModel) => res);
  }

  public static getThingsToDoContent(): Promise<ThingsToDoViewModel> {
    return fetch(`${baseUrl}/GetThingsToDoContent`)
      .then((res) => res.json())
      .then((res: ThingsToDoViewModel) => res);
  }

  public static getLocationContent(): Promise<LocationViewModel> {
    return fetch(`${baseUrl}/GetLocationContent`)
      .then((res) => res.json())
      .then((res: LocationViewModel) => res);
  }

  public static getPressRelease(releaseIndex: string): Promise<NewsViewModel> {
    return fetch(`${baseUrl}/GetPressRelease?releaseIndex=${releaseIndex}`)
      .then((res) => res.json())
      .then((res: NewsViewModel) => ({
        ...res,
        // newsItems: res.newsItems?.map(
        //   (item) =>
        //     ({
        //       ...item,
        //       date: new Date(item.date),
        //     } as NewsItemViewModel)
        // ),
      }));
  }

  public static getNewsContent(): Promise<NewsViewModel> {
    return fetch(`${baseUrl}/GetNewsContent`)
      .then((res) => res.json())
      .then(
        (res: NewsViewModel) =>
          ({
            ...res,
            // newsItems: res.newsItems?.map(
            //   (item) =>
            //     ({
            //       ...item,
            //       date: new Date(item.date),
            //     } as NewsItemViewModel)
            // ),
          } as NewsViewModel)
      );
  }

  public static getNewsPage(
    pageNumber: number
  ): Promise<NewsPageResponseViewModel> {
    return fetch(`${baseUrl}/GetNewsPage?page=${pageNumber}`)
      .then((res) => res.json())
      .then((res: NewsPageResponseViewModel) => res);
  }

  public static getLeasingContent(): Promise<LeasingViewModel> {
    return fetch(`${baseUrl}/GetLeasingContent`)
      .then((res) => res.json())
      .then((res: LeasingViewModel) => res);
  }

  public static getFactContent(): Promise<FactListViewModel> {
    return fetch(`${baseUrl}/GetFactContent`)
      .then((res) => res.json())
      .then((res: FactListViewModel) => res);
  }
}
