export enum PagePath {
  Home = "/",
  About = "/about",
  ThingsToDo = "/things-to-do",
  Location = "/location",
  Leasing = "/leasing",
  News = "/news",
  PressRelease = "/press-release",
  Unknown = "",
}

export function locationToPagePath(path: string): PagePath {
  if (Object.values(PagePath).includes(path as PagePath)) {
    return path as PagePath;
  }

  return PagePath.Unknown;
}
