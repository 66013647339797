import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import UmbracoApi from "../util/umbracoApi";
import { ImageViewModel } from "../view-models/image-view-model";
import Picture from "./Picture";
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";
import "./Navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkViewModel } from "../view-models/link-view-model";
import { Link } from "react-router-dom";
import { getLinkColor } from "../util/linkColor";
import { locationToPagePath, PagePath } from "../util/pagePath";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

enum NavLogoStyle {
  Horizontal = "horizontal",
  Regular = "regular",
}

enum NavLogoColor {
  White = "white",
  Dark = "dark",
}

export interface NavbarProps {}

const Navbar = (props: NavbarProps) => {
  const [links, setLinks] = useState<LinkViewModel[]>([]);

  const [horizontalDarkLogo, setHorizontalDarkLogo] =
    useState<ImageViewModel>();
  const [horizontalBrightLogo, setHorizontalBrightLogo] =
    useState<ImageViewModel>();
  const [brightLogo, setBrightLogo] = useState<ImageViewModel>();
  const [darkLogo, setDarkLogo] = useState<ImageViewModel>();
  const [shownLogo, setShownLogo] = useState<ImageViewModel>();
  const [shownIcon, setShownIcon] = useState(faBars);
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const navLinksRef = useRef<HTMLDivElement>(null);
  const [pagePath, setPagePath] = useState(PagePath.Unknown);

  const [logoStyle, setLogoStyle] = useState<NavLogoStyle>(
    NavLogoStyle.Regular
  );
  const [logoColor, setLogoColor] = useState<NavLogoColor>(NavLogoColor.White);

  const location = useLocation();

  useEffect(() => {
    setPagePath(locationToPagePath(location.pathname));

    UmbracoApi.getNavContent().then((nav) => {
      if (!nav) return;

      setLinks(nav.links);
      setBrightLogo(nav.brightLogo);
      setDarkLogo(nav.darkLogo);

      setHorizontalDarkLogo(nav.horizontalDarkLogo);
      setHorizontalBrightLogo(nav.horizontalBrightLogo);
    });
  }, [location]);

  // Set the proper classes for the navbar expansion
  useEffect(() => {
    function getLogoColorForPage(): NavLogoColor {
      switch (pagePath) {
        case PagePath.Home:
          return NavLogoColor.Dark;

        case PagePath.News:
          return NavLogoColor.Dark;

        case PagePath.PressRelease:
          return NavLogoColor.Dark;

        default:
          return NavLogoColor.White;
      }
    }

    if (expanded) {
      navbarRef.current?.classList.add("expanded");
      navbarRef.current?.classList.remove("closed");

      navLinksRef.current?.classList.add("expanded");
      navLinksRef.current?.classList.remove("closed");

      setShownIcon(faTimes);
      setLogoColor(NavLogoColor.White);
    } else {
      navbarRef.current?.classList.add("closed");
      navbarRef.current?.classList.remove("expanded");

      navLinksRef.current?.classList.add("closed");
      navLinksRef.current?.classList.remove("expanded");

      setShownIcon(faBars);
      setLogoColor(getLogoColorForPage());
    }
  }, [pagePath, navbarRef, navLinksRef, expanded]);

  // When we change the page, we need to reset the navbar expansion
  useEffect(() => {
    setShownIcon(faBars);
    setExpanded(false);
  }, [location]);

  useEffect(() => {
    switch (pagePath) {
      case PagePath.Home:
        setLogoStyle(NavLogoStyle.Horizontal);
        setLogoColor(NavLogoColor.Dark);
        break;

      case PagePath.News:
        setLogoStyle(NavLogoStyle.Regular);
        setLogoColor(NavLogoColor.Dark);
        break;

      case PagePath.PressRelease:
        setLogoStyle(NavLogoStyle.Regular);
        setLogoColor(NavLogoColor.Dark);
        break;

      case PagePath.Unknown:
        setLogoStyle(NavLogoStyle.Regular);
        setLogoColor(NavLogoColor.Dark);
        break;

      default:
        setLogoStyle(NavLogoStyle.Regular);
        setLogoColor(NavLogoColor.White);
    }
  }, [pagePath]);

  useEffect(() => {
    if (logoStyle === NavLogoStyle.Horizontal) {
      if (logoColor === NavLogoColor.Dark) {
        setShownLogo(horizontalDarkLogo);
      } else {
        setShownLogo(horizontalBrightLogo);
      }
    } else {
      if (logoColor === NavLogoColor.Dark) {
        setShownLogo(darkLogo);
      } else {
        setShownLogo(brightLogo);
      }
    }
  }, [
    logoStyle,
    logoColor,
    horizontalDarkLogo,
    horizontalBrightLogo,
    brightLogo,
    darkLogo,
  ]);

  function toggleExpandNav(event: React.MouseEvent<HTMLDivElement>) {
    setExpanded(!expanded);
  }

  return (
    <div id="navbar" className="navbar" ref={navbarRef}>
      <div className="ui container full-height">
        <div className="nav-section">
          <Link
            to={PagePath.Home}
            onClick={() => {
              setExpanded(false);
            }}
          >
            <Picture
              className={`logo ${logoStyle} ${logoColor}`}
              image={shownLogo}
            />
            <div className="expanded-logo"></div>
          </Link>
          <div className="nav-button" onClick={toggleExpandNav}>
            <FontAwesomeIcon icon={shownIcon as IconProp} />
          </div>
        </div>
      </div>

      <nav id="nav-links" ref={navLinksRef}>
        <div className="ui container">
          <div className="links">
            {links.map((link) => {
              if (
                link.url.startsWith("http://") ||
                link.url.startsWith("https://")
              ) {
                return (
                  <a
                    className={`link ${getLinkColor(
                      locationToPagePath(link.url)
                    )}`}
                    href={link.url}
                    target={link.target}
                    key={link.name}
                  >
                    {link.name}
                  </a>
                );
              } else {
                return (
                  <Link
                    className={`link ${getLinkColor(
                      locationToPagePath(link.url)
                    )}`}
                    to={link.url}
                    target={link.target}
                    key={link.name}
                    onClick={() => {
                      setExpanded(false);
                    }}
                  >
                    {link.name}
                    <br />
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
