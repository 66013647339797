import { useEffect, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { BuildingInfoViewModel } from "../view-models/building-info-view-model";
import { FeatureBlockViewModel } from "../view-models/feature-block-view-model";
import { ImageViewModel } from "../view-models/image-view-model";
import "./Leasing.scss";
import LeasingMap from "./LeasingMap";
import PiedmontDescription from "./PiedmontDescription";
import PiedmontHero from "./PiedmontHero";
import PiedmontSeparator from "./PiedmontSeparator";
import PiedmontSpacer from "./PiedmontSpacer";
import RawHtml from "./RawHtml";

export interface LeasingProps {
  className: string;
}

const Leasing = (props: LeasingProps) => {
  const [pageTitle, setPageTitle] = useState("");
  const [heroImage, setHeroImage] = useState<ImageViewModel>({});
  const [subtitle, setSubtitle] = useState("");
  const [buildings, setBuildings] = useState<BuildingInfoViewModel[]>([]);
  const [amenitiesDescription, setAmenitiesDescription] = useState("");
  const [amenities, setAmenities] = useState<FeatureBlockViewModel[]>([]);
  const [leasingEmail, setLeasingEmail] = useState("");

  const [firstAmenitiesColumn, setFirstAmenitiesColumn] = useState<
    FeatureBlockViewModel[]
  >([]);
  const [secondAmenitiesColumn, setSecondAmenitiesColumn] = useState<
    FeatureBlockViewModel[]
  >([]);
  const [thirdAmenitiesColumn, setThirdAmenitiesColumn] = useState<
    FeatureBlockViewModel[]
  >([]);

  const colors = ["green", "red", "blue"];

  useEffect(() => {
    UmbracoApi.getLeasingContent().then((leasing) => {
      setPageTitle(leasing.pageTitle);
      setHeroImage(leasing.heroImage);
      setSubtitle(leasing.subtitle);
      setBuildings(leasing.buildings);
      setAmenitiesDescription(leasing.amenitiesDescription);
      setAmenities(leasing.amenities);
      setLeasingEmail(leasing.email);
    });
  }, []);

  useEffect(() => {
    const groupLength = Math.round(amenities.length / 3);

    setFirstAmenitiesColumn(amenities.slice(0, groupLength));
    setSecondAmenitiesColumn(amenities.slice(groupLength, groupLength * 2));
    setThirdAmenitiesColumn(amenities.slice(groupLength * 2));
  }, [amenities]);

  const amenityElement = (
    a: FeatureBlockViewModel,
    index: number,
    offset: number
  ) => {
    return (
      <div className="amenity" key={`amenity ${a.title}`}>
        <div
          className={`title ${colors[(index + offset) % colors.length]}`}
          key={`title ${a.title}`}
        >
          {a.title}
        </div>
        <RawHtml
          className="amenities-list"
          html={a.description}
          key={`rawhtml ${a.title}`}
        />
      </div>
    );
  };

  return (
    <div className="leasing">
      <div className="ui container">
        <PiedmontHero title={pageTitle} image={heroImage} />

        <PiedmontSeparator thick={true} color="lightgray" />
        <PiedmontDescription html={subtitle} />

        <PiedmontSeparator color="lightgray" />
        <LeasingMap buildings={buildings} />

        <PiedmontSeparator color="lightgray" />
        <PiedmontDescription
          className="leasing-description"
          html={amenitiesDescription}
        />

        <div className="amenities">
          <div className="amenities-group">
            {firstAmenitiesColumn.map((amenity, i) =>
              amenityElement(amenity, i, 0)
            )}
          </div>
          <div className="amenities-group">
            {secondAmenitiesColumn.map((amenity, i) =>
              amenityElement(amenity, i, 2)
            )}
          </div>
          <div className="amenities-group">
            {thirdAmenitiesColumn.map((amenity, i) =>
              amenityElement(amenity, i, 1)
            )}
          </div>
          {/* <Amenities /> */}
        </div>

        <PiedmontSeparator color="lightgray" />
        <div className="contact">
          <p>
            To explore spec suite availabilities, visit:
            <br />
            <a
              href="http://pcstartspec.com"
              target="_blank"
              rel="noreferrer"
              className="email"
            >
              pcstartspec.com
            </a>
          </p>
        </div>

        <PiedmontSeparator color="lightgray" />
        <div className="contact">
          <p>
            For leasing information, please email:
            <br />
            <a href={`mailto:${leasingEmail}`} className="email">
              {leasingEmail}
            </a>
          </p>
        </div>
        <PiedmontSeparator thick={true} color="lightgray" />
      </div>

      <PiedmontSpacer />
    </div>
  );
};

export default Leasing;
