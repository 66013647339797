import { ImageViewModel } from "../view-models/image-view-model";
import "./PiedmontHero.scss";
import Picture from "./Picture";
import RawHtml from "./RawHtml";
import { useEffect, useMemo, useRef, useState } from "react";
import { Parallax } from "react-scroll-parallax";

export interface PiedmontHeroProps {
  title: string;
  image: ImageViewModel;
}

const getDimensions = (): [number, number] => {
  let { innerWidth: width, innerHeight: height } = window;
  return [width, height];
};

const PiedmontHero = (props: PiedmontHeroProps) => {
  const [dimensions, setDimensions] = useState<[number, number]>(
    getDimensions()
  );
  const titleRef = useRef(null);
  const imageRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [width, _] = useMemo(() => {
    return dimensions;
  }, [dimensions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setDimensions(getDimensions());
    });
  }, []);

  return (
    <div className="piedmont-hero">
      <div className="picture" ref={imageRef}>
        <Parallax disabled={width < 1024} y={[-15, 10]}>
          <Picture image={props.image} />
        </Parallax>
      </div>
      <div id="piedmont-title" className="title" ref={titleRef}>
        <Parallax disabled={width < 1024} y={[20, -20]}>
          <RawHtml html={props.title} />
        </Parallax>
      </div>
    </div>
  );
};

export default PiedmontHero;
