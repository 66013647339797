import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UmbracoApi from "../util/umbracoApi";
import "./News.scss";
import NewsList from "./NewsList";
import PiedmontSeparator from "./PiedmontSeparator";
import PressRelease from "./PressRelease";

export interface NewsProps {
  className: string;
}

const News = (props: NewsProps) => {
  const [pageTitle, setPageTitle] = useState("");
  const [blurb, setBlurb] = useState("");

  useEffect(() => {
    UmbracoApi.getNewsContent().then((news) => {
      if (news.pageTitle) setPageTitle(news.pageTitle);
      // if (news.pressReleaseBlurb) setBlurb(news.pressReleaseBlurb);
    });
  }, []);

  return (
    <div className={`news ${props.className}`}>
      <div className="ui container">
        <h2 className="header">{pageTitle}</h2>
        <PiedmontSeparator thick />

        {/* <PressRelease color="blue" fullRelease={false} content={blurb} /> */}

        {/* <Link to="/press-release">
          <button className="press-release-button button">
            View Press Release
          </button>
        </Link> */}

        <NewsList />

        <PiedmontSeparator thick={true} />
      </div>
    </div>
  );
};

export default News;
