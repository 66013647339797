import PiedmontHero from "./PiedmontHero";
import "./ThingsToDo.scss";
import PiedmontSeparator from "./PiedmontSeparator";
import PiedmontSwiper from "./PiedmontSwiper";
import { useEffect, useState } from "react";
import UmbracoApi from "../util/umbracoApi";
import { ImageViewModel } from "../view-models/image-view-model";
import { FeatureBlockViewModel } from "../view-models/feature-block-view-model";
import Picture from "./Picture";
import RawHtml from "./RawHtml";
import PiedmontSpacer from "./PiedmontSpacer";

export interface ThingsToDoProps {
  className?: string;
}

const ThingsToDo = (props: ThingsToDoProps) => {
  const [pageTitle, setPageTitle] = useState("");
  const [heroImage, setHeroImage] = useState<ImageViewModel>({});
  const [images, setImages] = useState<ImageViewModel[]>([]);
  const [events, setEvents] = useState<FeatureBlockViewModel[]>([]);

  useEffect(() => {
    UmbracoApi.getThingsToDoContent().then((thingsToDo) => {
      setPageTitle(thingsToDo.pageTitle);
      setHeroImage(thingsToDo.heroImage);
      if (thingsToDo?.images) setImages(thingsToDo.images);
      if (thingsToDo?.events) setEvents(thingsToDo.events);
    });
  }, []);
  return (
    <div className="things-to-do">
      <div className="ui container">
        <PiedmontHero title={pageTitle} image={heroImage} />

        <div className="things-to-do-swiper">
          <PiedmontSeparator thick={true} />
          <PiedmontSwiper images={images} />
        </div>

        <PiedmontSeparator />
        <div className="events">
          {events.map((event) => {
            return (
              <div className="event">
                <Picture image={event.image} />

                <div className="text">
                  <span className="title">{event.title}</span>
                  <RawHtml className="description" html={event.description} />
                </div>
              </div>
            );
          })}
        </div>

        <PiedmontSpacer />
      </div>
    </div>
  );
};

export default ThingsToDo;
