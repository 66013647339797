import { Link } from "react-router-dom";
import { ImageViewModel } from "../view-models/image-view-model";
import Picture from "./Picture";
import PiedmontSeparator from "./PiedmontSeparator";
import "./PressRelease.scss";
import RawHtml from "./RawHtml";

export type PressReleaseColor = "darkblue" | "blue";

export interface PressReleaseProps {
  className?: string;
  image?: ImageViewModel;
  fullRelease: boolean;
  content: string;
  color: PressReleaseColor;
  releaseId: number;
}

const Release = (props: {
  image?: ImageViewModel;
  fullRelease: boolean;
  color: PressReleaseColor;
  content: string;
  id: number;
}) => {
  if (props.fullRelease) {
    return (
      <>
        {/* {props.image && <Picture image={props.image} />} */}

        <RawHtml className={`release ${props.color}`} html={props.content} />
      </>
    );
  } else {
    return (
      <>
        <div className="subheader">Press Release</div>

        <RawHtml className={`release ${props.color}`} html={props.content} />

        <Link to={`/press-release?releaseIndex=${props.id}`}>
          <button className="press-release-button button">
            View Press Release
          </button>
        </Link>
      </>
    );
  }
};

const PressRelease = (props: PressReleaseProps) => {
  return (
    <div className={`press-release ${props.className}`}>
      <Release
        fullRelease={props.fullRelease}
        color={props.color}
        content={props.content}
        id={props.releaseId}
      />
    </div>
  );
};

export default PressRelease;
