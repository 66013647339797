import { useEffect, useState } from "react";
import "./PiedmontSeparator.scss";

export type PiedmontSeparatorColor = "darkblue" | "lightgray";

export interface PiedmontSeparatorProps {
  className?: string;
  color?: PiedmontSeparatorColor;
  thick?: boolean;
}

const PiedmontSeparator = (props: PiedmontSeparatorProps) => {
  const [color, setColor] = useState<PiedmontSeparatorColor>("darkblue");
  const thickClass = props.thick ? "thick" : "";

  useEffect(() => {
    if (props.color) setColor(props.color);
  }, [props.color]);

  return (
    <div
      className={`separator-line ${thickClass} ${props.className} ${color}`}
    ></div>
  );
};

export default PiedmontSeparator;
