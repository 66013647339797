import { Swiper, SwiperSlide } from "swiper/react";
import SwiperJS, { Autoplay, Pagination } from "swiper";
import { ImageViewModel } from "../view-models/image-view-model";
import Picture from "./Picture";
import "./PiedmontSwiper.scss";

SwiperJS.use([Pagination, Autoplay]);

export interface SwiperProps {
  className?: string;
  images: ImageViewModel[];
}

const PiedmontSwiper = (props: SwiperProps) => {
  const images = props.images;

  return (
    <div className={`swiper ${props.className}`}>
      <Swiper
        className="swiper"
        loop={true}
        pagination={true}
        speed={1500}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onSwiper={(swiper) => swiper.slideTo(1)}
        simulateTouch={false}
      >
        {images.map((image) => (
          <SwiperSlide key={image.desktopUrl}>
            <Picture image={image} key={image.desktopUrl} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PiedmontSwiper;
